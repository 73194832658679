export const themes = (theme) => {
    switch (theme) {
        case 'beautydose':
            return {
                title: 'text-beautydose-pink font-bold font-Inter',
                subtitle: 'text-beautydose-pink font-bold font-Inter text-2xl',
                description: 'text-beautydose-dark-gray font-Inter',
                background: 'bg-white',
                section: {
                    titleSettings: 'text-beautydose-dark-gray font-Inter',
                    background: 'bg-beautydose-gray-100 py-10 w-full max-w-[1600px] sm:px-16',
                },
                contentWithoutTable: {
                    titleSettings: '',
                    background: 'bg-white p-10',
                    info: {
                        background: 'bg-white mt-4',
                    }
                },
                contentWithTable: {
                    titleSettings: '',
                    background: 'bg-white p-10',
                    table: {
                        background: 'bg-white mt-8',
                        header: 'text-beautydose-dark-gray font-bold text-left text-sm font-Inter',
                        row: 'text-beautydose-dark-gray font-Inter'
                    },
                    navigation: {
                        page: {
                            active: 'text-white bg-beautydose-pink ring-1 ring-gray-300',
                            inactive: 'bg-white text-beautydose-dark-gray ring-1 ring-gray-300'
                        },
                        arrow: {
                            active: 'text-beautydose-pink bg-white hover:bg-gray-50',
                            inactive: 'pointer-events-none bg-white text-gray-300',
                            left: 'rounded-l-md ring-1 ring-gray-300',
                            right: 'rounded-r-md ring-1 ring-gray-300'
                        }
                    },
                },
                contentWthoutTable: {
                    titleSettings: '',
                    background: 'bg-white p-10',
                    
                },
                form: {
                    background: 'bg-white rounded-3xl p-5',
                    input: 'bg-white text-beautydose-pink',
                    switch: {
                        active: 'bg-beautydose-pink text-beautydose-pink',
                        inactive: 'bg-gray-200 text-gray-400'
                    },
                    button: {
                        primary: 'bg-beautydose-pink text-white font-Inter rounded-md px-5 py-2',
                        secondary: 'text-gray-500 ring-1 hover:ring-2 ring-gray-500 rounded-md hover:text-beautydose-pink hover:ring-beautydose-pink px-5 py-2'
                    },
                },
                loader: {
                    text: 'text-beautydose-dark-gray font-bold font-Inter',
                    spinner: 'text-gray-400'
                },
                notification: {
                    background:'bg-white border-beautydose-pink border-l-8 rounded-lg'
                }
            }
        case 'vaeo':
            return {
                title: 'text-vaeo-dark font-light font-Inter',
                subtitle: 'text-vaeo-dark font-semibold font-Inter text-2xl',
                description: 'text-vaeo-gray',
                background: 'bg-vaeo-bg',
                section: {
                    titleSettings: 'text-vaeo-dark font-Inter',
                    background: 'bg-vaeo-cream py-10 w-full max-w-[1600px] sm:px-16',
                },
                contentWithoutTable: {
                    titleSettings: '',
                    background: 'bg-white p-10',
                    info: {
                        background: 'bg-white mt-4',
                    }
                },
                contentWithTable: {
                    titleSettings: '',
                    background: 'bg-white p-10',
                    table: {
                        background: 'bg-white mt-8',
                        header: 'text-vaeo-dark-blue font-bold text-left text-sm font-Inter',
                        row: 'text-vaeo-dark-blue font-Inter'
                    },
                    navigation: {
                        page: {
                            active: 'text-white bg-vaeo-orange ring-1 ring-gray-300',
                            inactive: 'bg-white text-vaeo-gray ring-1 ring-gray-300'
                        },
                        arrow: {
                            active: 'text-vaeo-orange bg-white hover:bg-gray-50',
                            inactive: 'pointer-events-none bg-white text-gray-300',
                            left: 'rounded-l-md ring-1 ring-gray-300',
                            right: 'rounded-r-md ring-1 ring-gray-300'
                        }
                    },
                },
                form: {
                    background: 'bg-white rounded-3xl p-5',
                    input: 'bg-white text-vaeo-orange',
                    switch: {
                        active: 'bg-vaeo-orange text-vaeo-orange',
                        inactive: 'bg-gray-200 text-gray-400'
                    },
                    button: {
                        primary: 'bg-vaeo-orange text-white font-Inter rounded-md px-5 py-2',
                        secondary: 'text-gray-500 ring-1 hover:ring-2 ring-gray-500 rounded-md hover:text-vaeo-orange hover:ring-vaeo-orange px-5 py-2'
                    },
                },
                loader: {
                    text: 'text-vaeo-gray font-bold font-Inter',
                    spinner: 'text-vaeo-gray'
                },
                notification: {
                    background:'bg-white border-vaeo-orange border-l-8 rounded-lg'
                }
            }
        case 'lfx':
            return {
                title: 'text-lfx-brown font-bold font-Inter',
                subtitle: 'text-black font-bold font-Inter  text-2xl',
                description: 'text-gray-700',
                background: 'bg-white',
                section: {
                    titleSettings: 'text-gray-700 font-Inter',
                    background: 'bg-lfx-gray py-10 w-full max-w-[1600px] sm:px-16',
                },
                contentWithoutTable: {
                    titleSettings: '',
                    background: 'bg-white p-10',
                    info: {
                        background: 'bg-white mt-4',
                    }
                },
                contentWithTable: {
                    titleSettings: '',
                    background: 'bg-white p-10',
                    table: {
                        background: 'bg-white mt-8',
                        header: 'text-gray-700 font-bold text-left text-sm font-Inter',
                        row: 'text-gray-700 font-Inter'
                    },
                    navigation: {
                        page: {
                            active: 'text-white bg-lfx-brown ring-1 ring-gray-300',
                            inactive: 'bg-white text-gray-700 ring-1 ring-gray-300'
                        },
                        arrow: {
                            active: 'text-lfx-brown bg-white hover:bg-gray-50',
                            inactive: 'pointer-events-none bg-white text-gray-300',
                            left: 'rounded-l-md ring-1 ring-gray-300',
                            right: 'rounded-r-md ring-1 ring-gray-300'
                        }
                    },
                },
                form: {
                    background: 'bg-white rounded-3xl p-5',
                    input: 'bg-white text-lfx-brown',
                    switch: {
                        active: 'bg-lfx-brown text-lfx-brown',
                        inactive: 'bg-gray-200 text-gray-400'
                    },
                    button: {
                        primary: 'bg-lfx-brown text-white font-Inter px-5 py-2',
                        secondary: 'text-gray-500 ring-1 hover:ring-2 ring-gray-500 hover:text-lfx-brown hover:ring-lfx-brown px-5 py-2'
                    },
                },
                loader: {
                    text: 'text-gray-700 font-bold font-Inter',
                    spinner: 'text-gray-700'
                },
                notification: {
                    background:'bg-white border-lfx-brown border-l-8 rounded-lg'
                }
            }
        case 'svision':
            return {
                title: 'text-svision-black font-extrabold font-Geologica',
                subtitle: 'text-svision-white font-semibold font-Geologica text-2xl',
                description: 'text-svision-blue-dark',
                background: 'bg-svision-black px-5',
                section: {
                    titleSettings: 'text-svision-white font-Geologica',
                    background: 'bg-svision-white rounded-[30px] py-10 w-full max-w-[1600px] sm:px-16',
                },
                contentWithoutTable: {
                    titleSettings: 'mx-7 my-5',
                    background: 'bg-svision-blue px-2 pb-2 pt-5 rounded-[30px]',
                    info: {
                        background: 'bg-white py-5 px-7 mt-4 rounded-[25px]',
                    }
                },
                contentWithTable: {
                    titleSettings: 'mx-5 sm:mx-10 my-3',
                    background: 'bg-svision-blue px-2 pb-2 pt-5 rounded-[30px]',
                    table: {
                        background: 'bg-white p-5 mt-5 rounded-[25px]',
                        header: 'text-svision-blue-dark font-bold text-center text-sm font-Geologica',
                        row: 'text-black text-center font-Geologica'
                    },
                    navigation: {
                        page: {
                            active: 'text-svision-blue bg-white font-bold',
                            inactive: 'bg-white text-svision-blue-dark font-bold'
                        },
                        arrow: {
                            active: 'text-svision-blue bg-white ring-svision-blue',
                            inactive: 'pointer-events-none bg-white text-gray-300 ring-gray-200',
                            left: 'mr-1 ',
                            right: 'ml-1 ',
                        }
                    },
                },
                form: {
                    background: 'bg-svision-blue-light rounded-[30px] py-5 px-7',
                    input: 'bg-white text-svision-blue ring-gray-100',
                    switch: {
                        active: 'bg-svision-blue text-svision-blue',
                        inactive: 'bg-gray-200 text-gray-400'
                    },
                    button: {
                        primary: 'font-bold text-svision-blue-dark ring-2 ring-svision-blue font-Geologica rounded-full px-5 py-2 hover:bg-svision-white',
                        secondary: 'text-svision-blue-light font-semibold ring-2 ring-svision-blue-light rounded-full px-5 py-2'
                    },
                },
                loader: {
                    text: 'text-white font-bold font-Geologica',
                    spinner: 'text-white'
                },
                notification: {
                    background:'bg-white border-svision-blue border-l-8 rounded-l-full'
                }
            }
        case 'clau':
            return {
                title: 'text-clau-black font-semibold font-DMSans',
                subtitle: 'text-clau-black font-semibold font-DMSans text-xl',
                description: 'text-clau-blue-dark',
                background: 'bg-clau-black px-5',
                section: {
                    titleSettings: 'text-clau-white font-DMSans',
                    background: 'bg-clau-white rounded-[20px] py-10 w-full max-w-[1600px] sm:px-16',
                },
                contentWithoutTable: {
                    titleSettings: 'mx-7 my-5 font-DMSans',
                    background: 'bg-clau-gray-light px-2 pb-2 pt-2 rounded-[20px]',
                    info: {
                        background: 'bg-white py-5 px-7 mt-4 rounded-[20px]',
                    }
                },
                contentWithTable: {
                    titleSettings: 'mx-5 sm:mx-10 my-3 font-DMSans',
                    background: 'bg-clau-gray-light px-2 pb-2 pt-2 rounded-[20px]',
                    table: {
                        background: 'bg-white p-5 mt-5 rounded-[20px]',
                        header: 'text-clau-blue-dark font-bold text-center text-sm font-DMSans',
                        row: 'text-black text-center font-DMSans'
                    },
                    navigation: {
                        page: {
                            active: 'text-clau-blue bg-white font-bold',
                            inactive: 'bg-white text-clau-blue-dark font-bold'
                        },
                        arrow: {
                            active: 'text-clau-blue bg-white ring-clau-blue',
                            inactive: 'pointer-events-none bg-white text-gray-300 ring-gray-200',
                            left: 'mr-1 ',
                            right: 'ml-1 ',
                        }
                    },
                },
                form: {
                    background: 'bg-clau-blue-light rounded-[30px] py-5 px-7',
                    input: 'bg-white text-clau-blue ring-gray-100',
                    switch: {
                        active: 'bg-clau-blue text-clau-blue',
                        inactive: 'bg-gray-200 text-gray-400'
                    },
                    button: {
                        primary: 'items-center  text-sm font-bold text-clau-blue-dark ring-2 ring-clau-blue font-DMSans rounded-full px-5 py-2 hover:bg-clau-white',
                        secondary: 'items-center text-sm text-clau-blue-light font-semibold ring-2 ring-clau-blue-light rounded-full px-5 py-2 bg-clau-blue-dark'
                    },
                },
                loader: {
                    text: 'clau-black font-bold font-DMSans',
                    spinner: 'clau-black'
                },
                notification: {
                    background:'bg-white border-clau-blue border-l-8 rounded-l-full'
                }
            }
            case 'pai':
                return {
                    title: 'text-blue-dark font-semibold font-Poppins',
                    subtitle: 'text-blue-dark font-semibold font-Poppins text-2xl',
                    description: 'text-blue-dark',
                    background: 'bg-white px-5',
                    section: {
                        titleSettings: 'text-pai-blue-dark font-Poppins',
                        background: 'bg-white rounded-[15px] py-10 w-full max-w-[1600px] sm:px-16',
                    },
                    contentWithoutTable: {
                        titleSettings: 'mx-7 my-5',
                        background: 'bg-pai-white px-2 pb-2 pt-5 rounded-[15px]',
                        info: {
                            background: 'bg-pai-white py-5 px-7 mt-4 rounded-[25px]',
                        }
                    },
                    contentWithTable: {
                        titleSettings: 'mx-5 sm:mx-10 my-3',
                        background: 'bg-pai-white px-2 pb-2 pt-5 rounded-[15px]',
                        table: {
                            background: 'bg-pai-white p-5 mt-5 rounded-[25px]',
                            header: 'text-pai-blue-dark font-bold text-center text-sm font-Poppins',
                            row: 'text-black bg-pai-white text-center font-Poppins'
                        },
                        navigation: {
                            page: {
                                active: 'text-pai-blue bg-pai-white font-bold',
                                inactive: 'bg-pai-white text-pai-blue-dark font-bold'
                            },
                            arrow: {
                                active: 'text-pai-blue bg-pai-white ring-pai-blue',
                                inactive: 'pointer-events-none bg-pai-white text-gray-300 ring-gray-200',
                                left: 'mr-1 ',
                                right: 'ml-1 ',
                            }
                        },
                    },
                    form: {
                        background: 'bg-white rounded-[15px] py-5 px-7',
                        input: 'bg-white text-pai-blue ring-pai-blue-dark',
                        switch: {
                            active: 'bg-pai-blue text-pai-blue',
                            inactive: 'bg-gray-200 text-gray-400'
                        },
                        button: {
                            primary: 'bg-transparent font-medium text-pai-blue-dark ring-1 ring-pai-blue font-Poppins rounded-lg px-5 py-2 hover:ring-2',
                            secondary: 'bg-pai-white text-pai-blue-dark font-medium ring-1 ring-pai-blue rounded-lg px-5 py-2 hover:ring-2'
                        },
                    },
                    loader: {
                        text: 'text-pai-blue-dark font-Poppins',
                        spinner: 'text-pai-blue-dark'
                    },
                    notification: {
                        background:'bg-white border-pai-blue-dark border-l-8 rounded-l-md'
                    }
                }
}}